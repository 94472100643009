import Axios from "axios";
import * as qs from "qs";
import {urls} from "../utils/urls";
import ApiAbstract from "./api.abstract";
import {convertDateToUTC, downloadFile, generateGetUrl} from "../utils/utils";
import type {ECHOMOData} from "../utils/types";
import Papa from "papaparse"

class DataService extends ApiAbstract {
  constructor() {
    super(urls.escerApi, "data");
    if(!DataService.instance) {
      DataService.instance = this;
    }
    return DataService.instance;
  }

  getCera20cDataFromPoint(formData) {
    return new Promise((resolve, reject) => {
      Axios({
        method: 'POST',
        url: this.url("cera20c"),
        data: qs.stringify(formData,
          {serializeDate: (d: Date) => d.toUTCString()}),
        responseType: 'blob',
      }).then((response) => {
        downloadFile(response);
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  getExtremumDates() {
    return new Promise((resolve, reject) => {
      Axios({
        method: "GET",
        url: this.url("cera20c/PR/extremum-dates"),
        responseType: 'application/json'
      }).then(response => {
        resolve([convertDateToUTC(new Date(response.data.startDate)), convertDateToUTC(new Date(response.data.endDate))]);
      }).catch(() => {
        reject();
      });
    });
  }

  getJsonData(id: number) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "GET",
        url: this.url(id),
        responseType: "application/json"
      }).then((response) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
  }

  getCsvData = (
    type: string,
    ...parameters
  ) => {
    return new Promise((resolve, reject) => {
      const url = generateGetUrl(
        this.url(`csv/${type}`),
        ...parameters);
      Papa.parse(url, {
        download: true,
        complete: (data) => {
          resolve(data);
        },
        error: () => {
          reject();
        }
      });
    })
  }
}

const dataService = new DataService();
Object.freeze(dataService);
export default dataService;
