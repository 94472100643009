import type {DataContainer, Option, Position} from "./types";

export const CanadaCenter: Position = {
  lat: 60,
  lng: -100
};

export const FrequencyOptions: DataContainer<Option> = {
  name: "Fréquence",
  data: [
  {value: "01", displayValue: "Janvier"},
  {value: "02", displayValue: "Février"},
  {value: "03", displayValue: "Mars"},
  {value: "04", displayValue: "Avril"},
  {value: "05", displayValue: "Mai"},
  {value: "06", displayValue: "Juin"},
  {value: "07", displayValue: "Juillet"},
  {value: "08", displayValue: "Août"},
  {value: "09", displayValue: "Septembre"},
  {value: "10", displayValue: "Octobre"},
  {value: "11", displayValue: "Novembre"},
  {value: "12", displayValue: "Décembre"},
  {value: null, displayValue: null},
  {value: "DJF", displayValue: "Hiver"},
  {value: "MAM", displayValue: "Printemps"},
  {value: "JJA", displayValue: "Été"},
  {value: "SON", displayValue: "Automne"},
  {value: null, displayValue: null},
  {value: "YEAR", displayValue: "Annuel"},
]};

export const DomainOptions: DataContainer<Option> = {
  name: "Domaine",
  data: [
    {value: "Domaine1", displayValue: "Domaine 1"},
    {value: "Domaine2", displayValue: "Domaine 2"},
    {value: "Domaine3", displayValue: "Domaine 3"},
    {value: "Domaine4", displayValue: "Domaine 4"},
  ]
};

export const PeriodOptions: DataContainer<Option> = {
  name: "Période",
  data: [
    {value: "2011-2040", displayValue: "2011-2040"},
    {value: "2041-2070", displayValue: "2041-2070"},
    {value: "2071-2100", displayValue: "2071-2100"}
  ]
};

export const VariableOptions: DataContainer<Option> = {
  name: "Variable",
  data: [
    {value: "Mean_tasmax", displayValue: "Tasmax"},
    {value: "Mean_tasmin", displayValue: "Tasmin"},
    {value: "PrecTOT", displayValue: "PrecTOT"}
  ]
};

export const ScenarioOptions: DataContainer<Option> = {
  name: "Scénario",
  data: [
    {value: "6_RCMs_Pilotes_GCMs_rcp45", displayValue: "rcp4.5"},
    {value: "9_RCMs_Pilotes_GCMs_rcp85", displayValue: "rcp8.5"}
  ]
};

export const SiteSections: Array<string> = [
  "Réanalyse",
  "Projections",
  "Ensembles",
];

export const ProvinceNames = {
  "BC": "Colombie-Britannique",
  "Nt": "Nunavut",
  "TNO": "Territoires du Nord-Ouest",
  "Alb": "Alberta",
  "NL": "Terre-Neuve et Labrador",
  "Sask": "Saskatchewan",
  "Man": "Manitoba",
  "Quebec": "Québec",
  "Ont": "Ontario",
  "NB": "Nouveau-Brunswick",
  "NS": "Nouvelle-Écosse",
  "IPE": "Île du Prince-Édouard",
  "Yn": "Yukon"
};

