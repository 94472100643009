import * as React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {Hidden} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import type {Route, SiteSection} from "../utils/types";
import ListSubheader from "@material-ui/core/ListSubheader";
import HomeIcon from '@material-ui/icons/Home';
import {Link} from "react-router-dom";
import AppRoutes from "../App.routes";
import {SiteSections} from "../utils/constants";


type Props = {
  mobileOpen: boolean,
  handleDrawerToggle: Function,
  currentRoute: Route,
}

const ResponsiveDrawer = (props: Props) => {
  const drawer = (
    <>
      {SiteSections.map((section: string, index: number) =>
        <List key={index}>
          <ListSubheader>{section.toUpperCase()}</ListSubheader>
          {AppRoutes.map((route, index) =>
            route.section === section &&
            <ListItem
              button
              key={index}
              component={Link}
              to={route.path}
              selected={route.path === props.currentRoute.path}>
              <ListItemText primary={route.name}/>
            </ListItem>
          )}
        </List>
      )}
    </>
  );

  return (
    <nav className="responsive-drawer">
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="left"
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: "drawer-paper",
          }}
          ModalProps={{
            keepMounted: true,
          }}>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: "drawer-paper",
          }}>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default ResponsiveDrawer;