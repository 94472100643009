import React from 'react';
import type {Option} from "../../utils/types";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import {FormControlLabel} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

type Props = {
  name: string,
  options: Array<Option>,
  onChange: Function,
  touched: boolean,
  valid: boolean
}
const RadiosInput = (props: Props) => {
  return (
    <FormControl  component={"fieldset"}>
      <RadioGroup>
        {
          props.options.map((option: Option, index: number) => (
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  value={option.value}
                  name={props.name}
                  onChange={props.onChange}
                />
              }
              label={option.displayValue}

              key={index}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  );
};

export default RadiosInput;