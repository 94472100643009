import React, {Component} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts';
import map from "highcharts/modules/map";
import Container from "@material-ui/core/Container";
import canadaMap from "@highcharts/map-collection/countries/ca/ca-all.geo";
import worldMap from "@highcharts/map-collection/custom/world.geo";
import {Typography} from "@material-ui/core";
import {FrequencyOptions, ProvinceNames} from "../../utils/constants";
import Grid from "@material-ui/core/Grid";
import SelectInput from "../../components/form-inputs/select.input";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

map(Highcharts);

type State = {
  frequency: string,
  province: {
    code: string,
    index: number
  },
  dygraph1Data: Object,
  dygraph2Data: Object,
}

const mapOptions = (onSelectListener: Function, selectedProvince: string) => {
  return {
    title: {
      text: `Province sélectionnée: ${selectedProvince}`
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: e => onSelectListener(e.point),
          }
        }
      }
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name}`
      }
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [{
      data: [
        ['ca-bc', 'BC'],
        ['ca-nu', 'Nt'],
        ['ca-nt', 'TNO'],
        ['ca-ab', 'Alb'],
        ['ca-nl', 'NL'],
        ['ca-sk', 'Sask'],
        ['ca-mb', 'Man'],
        ['ca-qc', 'Quebec'],
        ['ca-on', 'Ont'],
        ['ca-nb', 'NB'],
        ['ca-ns', 'NS'],
        ['ca-pe', 'IPE'],
        ['ca-yt', 'Yn']
      ],
      dataLabels: {
        enabled: true,
        format: '{point.name}'
      },
      mapData: canadaMap,

      allowPointSelect: true,
      cursor: 'pointer',
      states: {
        hover: {
          color: '#a4edba'
        },
        select: {
          color: '#EFFFEF',
          borderColor: 'black',
          dashStyle: 'dot'
        }
      },
    }]
  }
};


export default class ProjectionsProvincesView extends Component<{}, State> {
  state: State = {
    frequency: "01",
    province: {
      code: "Quebec",
      index: 7
    },
    dygraph1Data: null,
    dygraph2Data: null,
  };
  mapComponent = React.createRef();

  onSelectListener = (point) => {
    this.setState({
      province: {
        code: point.value,
        index: parseInt(point.x)
      }
    })
  };
  handleChange = (event) => {
    this.setState({frequency: event.target.value})
  };
  selectProvince = (index: number) => {
    this.mapComponent.current.chart.series[0].data[index].select();
  };

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    this.selectProvince(this.state.province.index)
  }

  componentDidMount() {
    const container = this.mapComponent.current.container.current;
    container.style.height = "100%";
    container.style.width = "100%";
    this.mapComponent.current.chart.reflow();
    //presélection de Québec
    this.selectProvince(7)
  }

  render() {
    return (
      <Container id="projections-provinces" maxWidth="xl">
        <Typography variant="body1">For each province over canada and each Regional Climate Model from CORDEX-NAM44 , we
          calculated the interannual anomaly over 1971-2000 and 2011-2100 periods compared with normal 1971-2000. In
          future condition (2011-2100), we used 6 simulations with rcp4.5 scenario and 9 simulations with rcp8.5
          scenario. In historical condition, we used 9 simulations.
          For each RCM, we calculated indices of extreme weather based on daily values of temperature and precipitation.
          We then estimate climatological change in future periods: 2011-2040, 2041-2070 and 2071-2100 compared with
          historical 1971-2000 period.
          Please first select a time period and then a province to display climate change projection. On scatter plot on
          right panel, you can change X-Axis and Y-Axis variables.</Typography>
        <Divider variant="middle"/>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <HighchartsReact
              highcharts={Highcharts}
              ref={this.mapComponent}
              constructorType={'mapChart'}
              options={mapOptions(this.onSelectListener, ProvinceNames[this.state.province.code])}
            />
            <FormControl>
              <SelectInput
                options={FrequencyOptions.data}
                onChange={this.handleChange}
                label={FrequencyOptions.name}
                value={this.state.frequency}
                inputName="frequency"/>
            </FormControl>
          </Grid>
          <Grid item lg={6}>

          </Grid>
        </Grid>


      </Container>
    );
  }
}