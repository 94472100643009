import React, {Component} from 'react';
import {Map, Marker, Popup, TileLayer} from "react-leaflet";
import type {FormField, Position} from "../../utils/types";
import {cleanCoordinates, createArrayFromRange, subtractDaysFromDate} from "../../utils/utils";
import * as L from "leaflet";
import validate from "../../utils/validators";

import "react-datepicker/dist/react-datepicker.css";
import DayInput from "../../components/form-inputs/day.input";
import MonthInput from "../../components/form-inputs/month.input";
import RadiosInput from "../../components/form-inputs/radios.input";
import YearsInput from "../../components/form-inputs/years.input";
import dataService from "../../services/data.service";
import Button from "@material-ui/core/Button";
import {InputLabel} from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import TabPanel from "../../components/tab-panel";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import {CanadaCenter} from "../../utils/constants";

type State = {
  center: Position,
  marker: Position,
  downloading: boolean,
  activeTab: number,
  minDate: Date,
  maxDate: Date,
  yearsRange: Array<number>,
  zoom: number,
  formIsValid: boolean,
  formControls: {
    latitude: FormField,
    longitude: FormField,
    variables: FormField,
    startDate: FormField,
    endDate: FormField,
  }
}

export default class Cera20cView extends Component<{}, State> {
  state: State = {
    center: CanadaCenter,
    marker: CanadaCenter,
    downloading: false,
    activeTab: 0,
    minDate: null,
    maxDate: null,
    yearRange: [],
    zoom: 4,
    formIsValid: false,
    formControls: {
      latitude: {
        value: CanadaCenter.lat,
        valid: true,
        validationRules: {
          required: true,
          isNumber: true
        },
        touched: true
      },
      longitude: {
        value: CanadaCenter.lng,
        valid: true,
        validationRules: {
          required: true,
          isNumber: true
        },
        touched: true
      },
      variable: {
        value: '',
        valid: false,
        validationRules: {
          required: true
        },
        touched: false,
        options: [
          {value: "SST", displayValue: "Température de surface de la mer"},
          {value: "PR", displayValue: "Précipitation totale (mm)"},
          {value: "SUND", displayValue: "Durée d'ensoleillement"},
          {value: "TAS", displayValue: "Température à 2 mètres"},
          {value: "MSLP", displayValue: "Pression moyenne au niveau de la mer"},
          {value: "SIC", displayValue: "Concentration de glace"}
        ]
      },
      startDate: {
        value: null,
        valid: true,
        validationRules: {
          isRequired: true,
        },
        touched: false
      },
      endDate: {
        value: null,
        valid: true,
        validationRules: {
          isRequired: true,
        },
        touched: false
      },
    },
  };

  updatePosition = latLng => {
    const updatedControls = this.state.formControls;
    let cleanedPosition = cleanCoordinates(latLng);
    updatedControls["latitude"].value = cleanedPosition.lat;
    updatedControls["longitude"].value = cleanedPosition.lng;
    this.setState({
      formControls: updatedControls,
      marker: cleanedPosition,
      center: cleanedPosition,
    })
  };

  handleDragging = e => {
    let cleanedPosition = cleanCoordinates(e.target._latlng);
    const updatedControls = this.state.formControls;
    updatedControls["latitude"].value = cleanedPosition.lat;
    updatedControls["longitude"].value = cleanedPosition.lng;
    this.setState({
      formControls: updatedControls,
      marker: cleanedPosition
    });
  };

  handleZoom = e => {
    this.setState({
      zoom: e.sourceTarget._zoom
    });
  };

  handleChange = (name: string, value: any) => {
    const updatedControls = this.state.formControls;
    const updatedFormElement: FormField = updatedControls[name];
    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
    updatedControls[name] = updatedFormElement;
    let formIsValid = true;
    for (let formControl in updatedControls) {
      // noinspection JSUnfilteredForInLoop
      if (!updatedControls[formControl].valid) {
        formIsValid = false;
        break;
      }
    }
    if (name === "longitude" || name === "latitude") {
      this.updatePosition(L.latLng(updatedControls.latitude.value, updatedControls.longitude.value))
    } else {
      this.setState({
        formControls: updatedControls,
        formIsValid: formIsValid
      });
    }
  };

  handleChangeStart = (date: Date) => {
    this.handleChange("startDate", date);
  };

  componentDidMount() {
    dataService.getExtremumDates()
      .then(dates => {
        this.setState({
          minDate: dates[0],
          maxDate: dates[1],
          yearRange: createArrayFromRange(dates[0].getFullYear(), dates[1].getFullYear())
        });
        this.handleChangeStart(dates[0]);
        this.handleChangeEnd(dates[1]);

      })
      .catch(() => {
        console.log("Erreur de récupération des dates extrema.")
      });
  }

  handleChangeEnd = (date: Date) => {
    this.handleChange("endDate", date)
  };

  toggleTab = (e: Event, value: number) => {
    this.setState({
      activeTab: value
    })
  };

  formSubmitHandler = e => {
    e.preventDefault();
    const formData = {};
    for (let formElementId in this.state.formControls) {
      // noinspection JSUnfilteredForInLoop
      formData[formElementId] = this.state.formControls[formElementId].value;
    }
    this.setState({
      downloading: true
    });
    dataService.getCera20cDataFromPoint(formData)
      .then(() => {
        this.setState({
          downloading: false
        });
      }).catch(() => {
      alert("Erreur lors de la récupération des données.");
      this.setState({
        downloading: false
      });
    });
  };

  render() {
    const position = [this.state.center.lat, this.state.center.lng];
    const markerPosition = [this.state.marker.lat, this.state.marker.lng];
    return (
      <Container  id="reanalysis-cera-20c" maxWidth="xl">
        <Box boxShadow={1} height={2/3} width="100%" mb={2}>
          <Map
            center={position}
            zoom={this.state.zoom}
            onZoom={this.handleZoom}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={markerPosition}
                    draggable={true}
                    autoPan={true}
                    onDrag={this.handleDragging}
                    onDragend={(e) => this.updatePosition(e.target._latlng)}>
              <Popup>
                Déplacez ce marqueur vers la localisation désirée.
              </Popup>
            </Marker>
          </Map>
        </Box>
        <form>
          <Card>
            <CardContent className="form-column">
              <Typography variant="h5">Coordonnées</Typography>
              <FormGroup>
                <div>
                  <InputLabel>Latitude</InputLabel>
                  <TextField
                    id="latitude"
                    type="number"
                    step="any"
                    value={this.state.formControls.latitude.value}
                    onChange={(e) => this.handleChange(e.target.id, e.target.value)}
                    variant="outlined"
                  />
                </div>
                <div>
                  <InputLabel>Longitude</InputLabel>
                  <TextField
                    id="longitude"
                    value={this.state.formControls.longitude.value}
                    type="number"
                    step="any"
                    onChange={(e) => this.handleChange(e.target.id, e.target.value)}
                    variant="outlined"
                  />
                </div>
              </FormGroup>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Typography variant="h5">Variable</Typography>
              <FormControl>
                <RadiosInput
                  name="variable"
                  options={this.state.formControls.variable.options}
                  onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                  touched={this.state.formControls.variable.touched}
                  valid={this.state.formControls.variable.valid}
                />
              </FormControl>
            </CardContent>
          </Card>
          <Card>
            <Tabs
              value={this.state.activeTab}
              onChange={this.toggleTab}
              indicatorColor="primary"
              textColor="primary"
              centered>
              <Tab label="Jours"/>
              <Tab label="Mois"/>
              <Tab label="Années"/>
            </Tabs>
            <CardContent className="form-column">
              <TabPanel index={0} value={this.state.activeTab}>
                <FormGroup>
                    <InputLabel htmlFor="startDay">Date de début</InputLabel>
                    <DayInput
                      selected={this.state.formControls.startDate.value}
                      selectsStart
                      startDate={this.state.formControls.startDate.value}
                      endDate={this.state.formControls.endDate.value}
                      minDate={this.state.minDate}
                      maxDate={this.state.formControls.endDate.value}
                      onChange={this.handleChangeStart}
                      id="startDay"
                    />
                    <InputLabel htmlFor="endDay">Date de fin</InputLabel>
                    <DayInput
                      selected={this.state.formControls.endDate.value}
                      selectsEnd
                      startDate={this.state.formControls.startDate.value}
                      endDate={this.state.formControls.endDate.value}
                      minDate={this.state.formControls.startDate.value}
                      maxDate={this.state.maxDate}
                      onChange={this.handleChangeEnd}
                      id="endDay"
                    />
                </FormGroup>
              </TabPanel>
              <TabPanel index={1} value={this.state.activeTab}>
                <FormGroup>
                  <InputLabel htmlFor="startMonth" >Mois de début</InputLabel>
                  <MonthInput
                    selected={this.state.formControls.startDate.value}
                    selectsStart
                    startDate={this.state.formControls.startDate.value}
                    endDate={this.state.formControls.endDate.value}
                    minDate={this.state.minDate}
                    maxDate={this.state.formControls.endDate.value}
                    onChange={this.handleChangeStart}
                    id="startMonth"
                  />
                  <InputLabel htmlFor="endMonth">Mois de fin</InputLabel>
                  <MonthInput
                    selected={this.state.formControls.endDate.value}
                    selectsEnd
                    startDate={this.state.formControls.startDate.value}
                    endDate={this.state.formControls.endDate.value}
                    minDate={this.state.formControls.startDate.value}
                    maxDate={this.state.maxDate}
                    onChange={this.handleChangeEnd}
                    id="endMonth"
                  />
                </FormGroup>
              </TabPanel>
              <TabPanel index={2} value={this.state.activeTab}>
                <InputLabel htmlFor="yearsSelect">Sélectionnez une ou plusieurs années</InputLabel>
                <YearsInput options={this.state.yearRange}
                            onChangeStart={this.handleChangeStart}
                            onChangeEnd={this.handleChangeEnd}/>
              </TabPanel>
            </CardContent>
          </Card>
          <Button
            color="secondary"
            className="download-button"
            type="submit"
            variant="contained"
            onClick={this.formSubmitHandler}
            disabled={!this.state.formIsValid || this.state.downloading}>
            {!this.state.downloading &&
            <>Télécharger <CloudDownloadIcon/></>
            }
            {this.state.downloading &&
            "Veuillez-patienter..."}
          </Button>
        </form>
      </Container>
    );
  }
}