import React from "react";
import {Select} from "@material-ui/core";
type Props = {
  options: Array<number>,
  onChangeStart: Function,
  onChangeEnd: Function
}
export default class YearsInput extends React.Component<Props, {}> {
  handleChange = (e) => {
    let selectedYears = [];
    [...e.target.options].filter(option => option.selected).map(option => selectedYears.push(option.value));
    let startDate = new Date(selectedYears[0], 0, 1);
    let endDate = new Date(selectedYears[selectedYears.length - 1 ], 11, 31);
    this.props.onChangeStart(startDate);
    this.props.onChangeEnd(endDate);
  };
  render() {
    return(
      <Select
        className="years-input"
        type="select"
        native
        name="selectMulti"
        inputProps={{id: "yearsSelect"}}
        onChange={this.handleChange}
        multiple>
        {this.props.options.map((option: number, index: number) => (
          <option key={index}>{option}</option>
        ))}
      </Select>
    )
  }
}