import React, {Component} from 'react';
import ResponsiveDrawer from "../components/responsive-drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import type {Route} from "../utils/types";
import {renderComponent} from "../utils/utils";

type Props = {
  activeRoute: Route
}

const DashboardLayout = (props: Props) => {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((mobileOpen: boolean) => !mobileOpen);
  };

  return (
    <div className="dashboard-layout">
      <ResponsiveDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        currentRoute={props.activeRoute}/>
      <AppBar className="app-bar">
        <Toolbar>
          <IconButton
            color="inherit"
            arial-label="afficher le menu"
            edge="start"
            onClick={handleDrawerToggle}
            className="menu-button">
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.activeRoute.section + " - " + props.activeRoute.name}
          </Typography>
        </Toolbar>
      </AppBar>
      {renderComponent(props.activeRoute.view)}
    </div>
  );
};

export default DashboardLayout;