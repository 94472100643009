import React, {Component} from "react";
import type {DataContainer, Position} from "./types";
import { saveAs } from 'file-saver';

export function cleanCoordinates(position: Position) {
  if (position.lat > 85) {
    position.lat = eval(position.lat) - 2 * 85;
  } else if (position.lat < -85) {
    position.lat = eval(position.lat) + 2 * 85;
  }

  if (position.lng > 180) {
    position.lng = eval(position.lng) - 2 * 180;
  } else if (position.lng < -180) {
    position.lng = eval(position.lng) + 2 * 180;
  }
  return position;
}

function extractFileName(contentDispositionValue) {
  let filename = "";
  if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(contentDispositionValue);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}

export function downloadFile(response) {
 let filename =  extractFileName(response.headers['content-disposition']);
 saveAs(response.data, filename);
}

export function convertDateToUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds());
}

export function createArrayFromRange(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export function extractRCMsTrendsFromDataArrays(parsedData: Array<Array>) {
  parsedData.splice(1, 1);
  let extractedData: Array<DataContainer> = [];
  let namesLine = parsedData.shift();
  namesLine.shift();
  namesLine.map((name: string) => {
    let dataContainer: DataContainer = {
      name: name,
      data: [[]]
    };
    extractedData.push(dataContainer);
  });
  parsedData.map(
    (line: Array<number>) => {
      line.shift();

      line.map((value: number, index: number) =>
        extractedData[index].data[0].push(parseFloat(value))
      )
    }
  );
  return extractedData;
}

export function renderComponent(Component: Node) {
  return <Component/>
}

export function generateGetUrl(path: string, ...parameters: string) {
  let url = `${path}?`;
  parameters.map((parameter: string, index: string) => {
    url += `${parameter}${index < parameters.length - 1 ? "&": ""}`
  });
  return url;
}
