import ApiAbstract from "./api.abstract";
import {urls} from "../utils/urls";
import Axios from "axios";
import * as qs from "qs";
import {downloadFile, generateGetUrl} from "../utils/utils";

class FileService extends ApiAbstract {
  constructor() {
    super(urls.escerApi, "files");
    if(!FileService.instance) {
      FileService.instance = this;
    }
    return FileService.instance;
  }

  generateModelizationUrl = (
    frequency: string,
    variable: string,
    scenario: string,
    period: string,
    domain: string) => {
    return generateGetUrl(
      this.url(`modelizations/${domain}`),
      `variable=${variable}`,
      `period=${period}`,
      `scenario=${scenario}`,
      `frequency=${frequency}`
    );
  };

  generatePlotUrl = (
    frequency: string,
    variable: string,
    zone: string) => {
    return generateGetUrl(
      this.url("plots"),
      `variable=${variable}`,
      `frequency=${frequency}`,
      `zone=${zone}`
    );
  }
}

const fileService = new FileService();
Object.freeze(fileService);
export default fileService;