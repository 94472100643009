import Cera20cView from "./views/reanalysis/cera-20c.view";
import type {Route} from "./utils/types";
import NorthAmericaView from "./views/ensembles/north-america.view";
import ProjectionsCanadaView from "./views/projections/projections-canada.view";
import ProjectionsProvincesView from "./views/projections/projections-provinces.view";
import DashboardLayout from "./layouts/dashboard.layout";
const AppRoutes: Array<Route> = [
  {
    name: "CERA-20C",
    section: "Réanalyse",
    path: "/reanalyse/cera-20c",
    view: Cera20cView,
    layout: DashboardLayout
  },
  {
    name: "Amérique du Nord",
    section: "Ensembles",
    path: "/ensembles/amerique-du-nord",
    view: NorthAmericaView,
    layout: DashboardLayout
  },
  {
    name: "Canada",
    section: "Projections",
    path: "/projections/canada",
    view: ProjectionsCanadaView,
    layout: DashboardLayout
  },
  // {
  //   name: "Provinces",
  //   section: "Projections",
  //   path: "/projections/provinces",
  //   view: ProjectionsProvincesView,
  //   layout: DashboardLayout
  // },
];
export default AppRoutes;

