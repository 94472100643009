import L from "leaflet";
import { MapControl, withLeaflet } from "react-leaflet";
type Props = {
  content: string
}

class LeafletLegend extends MapControl {
  createLeafletElement(_props: Props): LeafletElement {}

  legend;
  componentDidMount() {
    this.legend = L.control({position: "topright"});
    this.legend.onAdd = (map) => {
     this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
     this._div.innerHTML = this.props.content;
     return this._div;
    };
    const { map } = this.props.leaflet;
    this.legend.addTo(map)
  }

  componentWillUpdate(nextProps: Props, nextState: State, nextContext: *): * {
    this.legend._container.innerHTML = nextProps.content;
  }
  componentWillUnmount() {}
}
export default withLeaflet(LeafletLegend);