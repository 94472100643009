import React from "react";
import type {Option} from "../../utils/types";
import {TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

type Props = {
  options: Array<Option>,
  onChange: Function,
  label: string,
  value: any,
  inputName: string,
}

const SelectInput = (props: Props) => {
  return (
    <TextField
      select
      variant="outlined"
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      inputProps={{name: props.inputName}}>
      {props.options.map((option: Option, index: number) =>
        option.value ?
          [<MenuItem key={index} value={option.value}>{option.displayValue}</MenuItem>]
          :
          [<MenuItem key={index} disabled/>]
      )}
    </TextField>
  );
};

export default SelectInput;