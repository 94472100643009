import React from "react";
import Container from "@material-ui/core/Container";
import {Typography} from "@material-ui/core";

const NotFound = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h3">Erreur 404 - Page introuvable</Typography>
      <Typography variant="h4">Le lien suivi semble brisé.</Typography>
    </Container>
  );
};

export default NotFound