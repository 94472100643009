import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {urls} from "../../utils/urls";
import Grid from "@material-ui/core/Grid";
import fileService from "../../services/file.service";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {FrequencyOptions, PeriodOptions, ScenarioOptions, VariableOptions} from "../../utils/constants";
import SelectInput from "../../components/form-inputs/select.input";

type State = {
  scenario: string,
  frequency: string,
  period: string,
  variable: string,
}
type Row = {
  model: string,
  drivenCondition: Array<string>,
  period: string
}

const names = {
  "6_RCMs_Pilotes_GCMs_rcp45" : "rcp4.5",
  "9_RCMs_Pilotes_GCMs_rcp85" : "rcp8.5"
};
function createData(model: string, drivenCondition: Array<string>, period: string) {
  return {model, drivenCondition, period};
}

const historicalConditionRows: Array<Row> = [
  createData("CRCM5-v1", ["CCCma-CanESM2", "MPI-ESM"], "1971-2000"),
  createData("CANRCM4", ["CCCma-CanESM2"], "1971-2000"),
  createData("HIRHAM5-v1", ["ICHEC-EC-EARTH"], "1971-2000"),
  createData("RCA4-v1", ["ICHEC-EC-EARTH", "CCCma-CanESM2"], "1971-2000"),
  createData("RegCM4", ["MPI-ESM-LR", "GFDL-ESM2M", "HadGEM2-ES"], "1971-2000"),
];

const futureConditionRows: Array<Row> = [
  createData("CRCM5-v1", ["CCCma-CanESM2_rcp45", "CCCma-CanESM2_rcp85", "MPI-ESM-LR_rcp45", "MPI-ESM-LR_rcp85"], "2011-2100"),
  createData("CANRCM4", ["CCCma-CanESM2_rcp45", "CCCma-CanESM2_rcp85"], "2011-2100"),
  createData("HIRHAM5-v1", ["ICHEC-EC-EARTH_rcp45", "ICHEC-EC-EARTH_rcp85"], "2011-2100"),
  createData("RCA4-v1", ["ICHEC-EC-EARTH_rcp45", "ICHEC-EC-EARTH_rcp85", "CCCma-CanESM2_rcp45", "CCCma-CanESM2_rcp85"], "2011-2100"),
  createData("RegCM4", ["MPI-ESM-LR_rcp85", "GFDL-ESM2M_rcp85", "HadGEM2-ES_rcp85"], "2011-2100"),
];


export default function NorthAmericaView() {
  const [values, setValues] = React.useState({
    scenario: "6_RCMs_Pilotes_GCMs_rcp45",
    frequency: "01",
    period: "2011-2040",
    variable: "Mean_tasmax"
  });

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }
  function renderRows(rows: Array<Row>) {
    return(
      rows.map( (row, index) => (
        <TableRow key={index}>
          <TableCell><Typography align="justify" variant="body1">{row.model}</Typography></TableCell>
          <TableCell>{row.drivenCondition.map((condition, index) => (
            <Typography align="justify" display="block" variant="body1" key={index}>
              {condition}
            </Typography>
          ))}</TableCell>
          <TableCell>
            <Typography align="center" variant="body1">{row.period}</Typography>
          </TableCell>
        </TableRow>
        ))
    );
  }
  return (
    <Container id="ensemble-north-america" maxWidth="xl">
      <Typography variant="body1">For the present work, we mainly focused on recent simulations from <Link href={urls.naCordex}>CORDEX-NAM44</Link> project and simulations developed from <Link href={urls.escer}>ESCER</Link> center and <Link href={urls.environmentCanada}>Environment Canada</Link>. In this table, the RCM (Regional Climate Model) matrix is presented for the current climate period (1971-2000), driven by various AOGCMs (Atmosphere-Ocean coupled Global Climate Models). In future condition (2011-2100), we used 6 simulations with rcp4.5 scenario and 9 simulations with rcp8.5 scenario. In historical condition, we used 9 simulations. </Typography>
      <Divider variant="middle"/>
      <Grid container justify="space-evenly" spacing={1} className="selects-container">
        <Grid item>
          <FormControl>
            <SelectInput
              options={ScenarioOptions.data}
              onChange={handleChange}
              label={ScenarioOptions.name}
              value={values.scenario}
              inputName="scenario"/>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <SelectInput
              options={FrequencyOptions.data}
              onChange={handleChange}
              label={FrequencyOptions.name}
              value={values.frequency}
              inputName="frequency"/>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <SelectInput
              options={PeriodOptions.data}
              onChange={handleChange}
              label={PeriodOptions.name}
              value={values.period}
              inputName="period"/>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <SelectInput
              options={VariableOptions.data}
              onChange={handleChange}
              label={VariableOptions.name}
              value={values.variable}
              inputName="variable"/>
          </FormControl>
        </Grid>
      </Grid>
      <Divider variant="middle"/>
      <Grid container spacing={2} justify="center">
        <Grid item xl={8}>
          <Card>
            <CardMedia
              component="img"
              image= {fileService.generateModelizationUrl(
                values.frequency,
                values.variable,
                values.scenario,
                values.period,
                "anomaly")}
            />
            <CardContent>
              <Typography variant="body2">
                This is the calculated monthly change in maximum temperature (°C) for the period <b>{values.period}</b> compared with <b>1971-2000</b>.
                The map is based on an ensemble with nine climate scenarios for the <b>{names[values.scenario]}</b> scenario. Hatching indicates regions with low incertainty and where 60% of models agree on the sign of change within one sandard deviation of the ensemble.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardMedia
              component="img"
              image= {fileService.generateModelizationUrl(
                values.frequency,
                values.variable,
                "9_RCMs_Pilotes_GCMs_histo",
                "1971-2000",
                "climatology")}
            />
            <CardContent>
              <Typography variant="body2">
                Here's the ensemble mean of 9 Regional Climate Model for monthly maximum temperature (°C) during control period 1971-2000.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardMedia
              component="img"
              image= {fileService.generateModelizationUrl(
                values.frequency,
                values.variable,
                values.scenario,
                values.period,
                "climatology")}
            />
            <CardContent>
              <Typography variant="body2">
                Here's the ensemble mean of 9 Regional Climate Model in future condition with scenario <b>{names[values.scenario]}</b> for the period <b>{values.period}</b>.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider/>
      <Typography variant="h4">Matrice</Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Modèle
              </TableCell>
              <TableCell align="center">
                Driven conditions
              </TableCell>
              <TableCell align="center">
                Période
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <em>RCMs in historical condition</em>
              </TableCell>
            </TableRow>
            {renderRows(historicalConditionRows)}
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <em>RCMs in future condition</em>
              </TableCell>
            </TableRow>
            {renderRows(futureConditionRows)}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
}
