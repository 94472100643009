import React, {Component} from "react";
import type {ECHOMOData, Option, Position} from "../../utils/types";
import {CanadaCenter, DomainOptions, FrequencyOptions} from "../../utils/constants";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {GeoJSON, LayerGroup, LayersControl, Map, Marker, Popup, TileLayer} from "react-leaflet";
import * as L from "leaflet";
import Box from "@material-ui/core/Box";
import dataService from "../../services/data.service";
import MarkerList from "../../components/marker-list";
import fileService from "../../services/file.service";
import Legend from "../../components/leaflet-legend";
import CardMedia from "@material-ui/core/CardMedia";
import {extractRCMsTrendsFromDataArrays} from "../../utils/utils";
import {generateBoxPlotConfig} from "../../assets/js/boxplot";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting'
import SelectInput from "../../components/form-inputs/select.input";

exporting(Highcharts);
addHighchartsMore(Highcharts);


const {BaseLayer, Overlay} = LayersControl;

type State = {
  center: Position,
  zoom: number,
  legend: string,
  markersData: Array<ECHOMOData>,
  frequency: string,
  zone: string,
  markersData: Array<ECHOMOData>,
  domainsData: Object,
  boxPlot1Data: Object,
  boxPlot2Data: Object
}

export default class ProjectionsCanadaView extends Component<{}, State> {
  state: State = {
    center: CanadaCenter,
    zoom: 3,
    legend: "<h4>Survolez une zone</h4>",
    markersData: null,
    domainsData: null,
    boxPlot1Data: null,
    boxPlot2Data: null,
    frequency: "01",
    zone: "Domaine1",
  };

  handleZoom = e => {
    this.setState({
      zoom: e.sourceTarget._zoom
    });
  };

  handleChange = e => {
    if (e.target.value !== this.state[e.target.name]) {
      this.setState({
          [e.target.name]: e.target.value,
        },
        () => this.fetchBoxPlotData()
      );
    }
  };


  fetchBoxPlotData = () => {
    let fetchBoxPlot1Data = dataService.getCsvData(

      "rcms_trends",
      `frequency=${this.state.frequency}`,
      "variable=tasmin",
      `zone=${this.state.zone.toLowerCase()}`
    );
    let fetchBoxPlot2Data = dataService.getCsvData(
      "rcms_trends",
      `frequency=${this.state.frequency}`,
      "variable=tasmax",
      `zone=${this.state.zone.toLowerCase()}`
    );
    Promise.all([fetchBoxPlot1Data, fetchBoxPlot2Data]).then(results => {
      this.setState({
        boxPlot1Data: extractRCMsTrendsFromDataArrays(results[0].data),
        boxPlot2Data: extractRCMsTrendsFromDataArrays(results[1].data)
      })
    })
  };

  componentDidMount(): void {
    Promise.all([dataService.getJsonData(0), dataService.getJsonData(1)]).then(results => {
      this.setState({
        markersData: results[0],
        domainsData: results[1],
      });
    });
    this.fetchBoxPlotData()
  }

  highlightFeature = e => {
    let layer = e.target;
    layer.setStyle({
      weight: 2,
      color: 'red',
      dashArray: '',
      fillOpacity: 0.2,
      fillColor: '#FD8D3C'
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    const props = layer.feature.properties;
    this.setState({
      legend: '<h4><b>Domaine: ' + props.id + '</b></h4><br> <h4>Lat 1:' + props.Lat1 + ' °N<br />Lat 2:' + props.Lat2 + ' °N<br /> <h4>Lon 1:' + props.Lon1 + ' °W<br />Lon 2:' + props.Lon2 + '°W</h4>'
    });
  };

  resetHighlight = e => {
    this.refs.geojson.leafletElement.resetStyle(e.target);
    this.setState({
      legend: "<h4>Survolez une zone</h4>"
    });
  };

  zoomToFeature = e => {
    this.refs.map.leafletElement.fitBounds(e.target.getBounds());
  };

  onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
      click: this.zoomToFeature
    })
  };

  render() {
    return (
      <Container id="projections-canada" maxWidth="xl">
        <Typography variant="body1">
          In this section, we divided Canada in 4 specifics area to display climate projection. For each domaine and
          each Regional Climate Model from CORDEX-NAM44 , we calculated the interannual anomaly over 1971-2000 and
          2011-2100 periods compared with normal 1971-2000. In future condition (2011-2100), we used 6 simulations with
          rcp4.5 scenario and 9 simulations with rcp8.5 scenario. In historical condition, we used 9 simulations.
          We used the Second Generation of Homogenized Temperature dataset (Vincent and al. 2012). The interactive map
          bellow indicate Environnement Canada stations' position over Canada for each domaine delimited with red box.
        </Typography>
        <Box boxShadow={1} height={2 / 3} width="100%" mb={2}>
          <Map
            ref="map"
            center={[this.state.center.lat, this.state.center.lng]}
            zoom={this.state.zoom}
            minZoom={3}
            maxZoom={10}
            onZoom={this.handleZoom}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {this.state.markersData !== null &&
            <MarkerList data={this.state.markersData}/>
            }
            {this.state.domainsData !== null &&
            <LayerGroup>
              <GeoJSON
                ref="geojson"
                data={this.state.domainsData}
                onEachFeature={this.onEachFeature}
                style={
                  {
                    fillColor: '#FFEDA0',
                    weight: 2,
                    opacity: 1,
                    color: '#FD8D3C',
                    fillOpacity: 0.2
                  }
                }
              />
              <Legend content={this.state.legend}/>
            </LayerGroup>
            }
          </Map>
        </Box>
        <Divider variant="middle"/>
        <Grid container justify="space-evenly" spacing={1} className="selects-container">
          <Grid item>
            <FormControl>
              <SelectInput
                options={FrequencyOptions.data}
                onChange={this.handleChange}
                label={FrequencyOptions.name}
                value={this.state.frequency}
                inputName="frequency"/>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <SelectInput
                options={DomainOptions.data}
                onChange={this.handleChange}
                label={DomainOptions.name}
                value={this.state.zone}
                inputName="zone"/>
            </FormControl>
          </Grid>
        </Grid>
        <Divider variant="middle"/>
        <Grid container spacing={2} justify="center">
          <Grid item lg={6}>
            <CardMedia
              component="img"
              image={fileService.generatePlotUrl(
                this.state.frequency,
                "Tmin",
                this.state.zone
              )}
            />
          </Grid>
          <Grid item lg={6}>
            <CardMedia
              component="img"
              image={fileService.generatePlotUrl(
                this.state.frequency,
                "Tmax",
                this.state.zone
              )}
            />
          </Grid>
          <Grid item xl={12}>
            <Typography variant="body1">These diagrams show the change in minimum and maximum temperature (°C) during
              the years 1971-2000 and 2011-2100 compared with normal 1971-2000. The bars historical homogenized
              temperature stations from Environment Canada. Red bars show temperatures above normal and the blue bars
              show temperatures below normal. The blue line shows the ensemble mean of 6 RCM (Regional Climate Model)
              with RCP4.5 scenario. The red line shows the ensemble mean of 9 RCM (Regional Climate Model) with RCP8.5
              scenario. The grey line shows the ensemble mean of 9 RCM (Regional Climate Model) under historical
              condition. </Typography>
          </Grid>
          {
            this.state.boxPlot1Data &&
            <Grid item lg={6}>
              <HighchartsReact
                highcharts={Highcharts}
                options={generateBoxPlotConfig(
                  this.state.zone,
                  this.state.frequency,
                  "Tasmin",
                  this.state.boxPlot1Data
                )}
              />
              <Typography variant="body1"> This panel present the relative change in total precipitation versus absolute
                change in temperature.</Typography>
            </Grid>
          }
          {
            this.state.boxPlot2Data &&
            <Grid item lg={6}>
              <HighchartsReact
                highcharts={Highcharts}
                options={generateBoxPlotConfig(
                  this.state.zone,
                  this.state.frequency,
                  "Tasmax",
                  this.state.boxPlot2Data
                )}
              />
              <Typography variant="body1"> This panel present the relative change in precipitation intensity versus
                relative change in total precipitation . </Typography>
            </Grid>
          }
        </Grid>
      </Container>
    );
  }
}