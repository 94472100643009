import React from 'react';
type Props = {
  children: Node,
  value: number,
  index: number
}
function TabPanel(props: Props) {
  const { children, value, index, ...other } = props;
  return (
    <>
      {value === index &&
        children
      }
    </>
  );
}

export default TabPanel;