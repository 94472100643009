import React from "react";
import DatePicker, {registerLocale} from "react-datepicker/es";
import {fr} from "date-fns/locale"
registerLocale('FR', fr);
type Props = {
  selected: Date,
  onChange: Function,
  minDate: Date,
  maxDate: Date
}
export default class DayInput extends React.Component<Props, {}> {
  render() {
    return(
      <>
        <DatePicker {...this.props}
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale="FR"
        />
      </>


    );
  }
}