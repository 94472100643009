import React from "react";
import DatePicker, {registerLocale} from "react-datepicker/es";
import {fr} from "date-fns/locale"
registerLocale('FR', fr);
type Props = {
  selected: Date,
  onChange: Function,
}
export default class MonthInput extends React.Component<Props, {}> {
  render() {
    return(
      <DatePicker {...this.props}
                  dateFormat="MM/yyyy"
                  locale="FR"
                  showMonthYearPicker
      />
    );
  }
}