const validate = (value, rules) => {
  let isValid = true;

  for (let rule in rules) {

    // noinspection JSUnfilteredForInLoop
    switch (rule) {
      case 'minSelected':
        // noinspection JSUnfilteredForInLoop
        isValid = isValid && minSelectedValidator(value, rules[rule]); break;

      default: isValid = true;
    }

  }

  return isValid;
};


/**
 * minLength Val
 * @param  value
 * @param  minSelected
 * @return
 */
const minSelectedValidator = (value: Set, minSelected: number) => {
  return value.size >= minSelected;
};

export default validate;