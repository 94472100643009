//Configuration des graphiques (page projections canada)

import type {DataContainer} from "../../utils/types";

const seriesBaseConfig = {
  lineWidth: 2,
  medianColor: '#0C5DA5',
  medianWidth: 2,
  stemColor: '#A63400',
  stemDashStyle: 'dot',
  stemWidth: 1,
  whiskerColor: '#A63400',
  whiskerLength: '20%',
  whiskerWidth: 3
};
const seriesColor = [
  "#bfff00",
  "#80ff00",
  "#40ff00",
  "#00ff00",
  "#00ff80",
  "#00ffbf",
  "#808080",
  "#00ffff",
  "#00bfff",
  "#0040ff",
  "#ff8000",
  "#ff4000",
  "#ff0000"
];

export function generateBoxPlotConfig(zone: string, frequency: number, variable: string, data: Array<DataContainer>) {
  let series = [];
  data.map((value: DataContainer, index: number) => {
    series.push({
      name: value.name,
      data: value.data,
      color: seriesColor[index],
      fillColor: seriesColor[index],
      ...seriesBaseConfig
    });
  });
  return (
    {
      chart: {
        type: 'boxplot'
      },
      title: {
        text: 'Boxplot '+ variable + " " + zone
      },
      subtitle: {
        text: frequency
      },
      legend: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'Decadal Trends [°Celcius/decade]'
        }
      },
      series: series
    }
  );
}