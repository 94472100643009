import React from "react";
import type {ECHOMOData} from "../utils/types";
import {LayerGroup, Marker, Popup} from "react-leaflet"
type Props = {
  data: Array<ECHOMOData>
}
const MarkerList = (props: Props) => {
  return <LayerGroup>
    {props.data.map((value: ECHOMOData) =>
      <Marker
        key={value.Identification}
        position={[value.lat, value.lng]}>
        <Popup>
          <b> Historical homogenized temperature station </b>
          <br/> <b>Name: </b>{value.Name}
          <br/> <b>ID: </b>{value.Identification}
          <br/> <b>Elevation: </b>{value.Altitude}
          <br/> <b>Y_ini: </b>{value.Year_ini}
          <br/> <b>Y_fin: </b>{value.Year_fin}
        </Popup>
      </Marker>
    )}
  </LayerGroup>
};

export default MarkerList;