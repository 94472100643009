import { createMuiTheme } from '@material-ui/core/styles';
import {blue, indigo} from "@material-ui/core/colors";

const CustomTheme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: blue
  }
});

export default CustomTheme;