import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {BrowserRouter} from "react-router-dom";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CustomTheme from "./assets/js/theme-customization";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <MuiThemeProvider theme= {CustomTheme}>
    <BrowserRouter basename={baseUrl}>
      <App/>
    </BrowserRouter>
  </MuiThemeProvider>
  ,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
