import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Route} from "react-router";
import Cera20cView from "./views/reanalysis/cera-20c.view";
import { StylesProvider } from '@material-ui/styles';
import './assets/scss/styles.scss'
//Font Awesome
import {CssBaseline} from "@material-ui/core";
import DashboardLayout from "./layouts/dashboard.layout";
import AppRoutes from "./App.routes";
import Switch from "react-router/Switch";
import NotFound from "./views/NotFound";
function App() {
  return (
    <StylesProvider injectFirst>
      <CssBaseline/>
      <Switch>
        <Route path="/" exact render={(props) => <DashboardLayout activeRoute={AppRoutes[0]}/>}/>
        {AppRoutes.map((route: Route, index: number) =>
          <Route key={index} path={route.path} render={(props) => <DashboardLayout activeRoute={route}/>} />
        )}
        <Route component={NotFound} />
      </Switch>
    </StylesProvider>
  );
}

export default App;
