export default class ApiAbstract {
  #API_URL;
  #path;
  constructor(apiUrl, path) {
    this.#API_URL = apiUrl;
    this.#path = path;
    if (this.constructor === ApiAbstract) {
      throw new Error(("Abstract classes can't be instantiated"))
    }
  }

  url(route = "") {
    return this.#API_URL + "/" + this.#path + "/" + route ;
  }
}